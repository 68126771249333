export type LabelProps = {
  id?: string;
  children: React.ReactNode;
  required?: boolean
};

export const Label: React.FC<LabelProps> = ({ id, children, required = false }) => {
  if (!id) {
    return (
      <div className="block text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">
        {children}
        {
          required &&
          <span className="text-rose-800">*</span>
        }
      </div>
    );
  }
  return (
    <label
      htmlFor={id}
      className="block text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">
      {children}
      {required && <span className="text-red-500">*</span>}
    </label>
  );
};
