import { IFile } from "types/global";
import { helper } from ".";
import { getAuthorizationHeader } from "utils/auth";

export class FormUtil {
  private minLenValue = {
    'phone': 9,
    'nik': 16
  }

  public field_spouse_map = new Map<string, string>([
    ["spouse.name", "Nama"],
    ["spouse.idNo", "KTP"],
    ["spouse.lastEducation", "Pendidikan"],
    ["spouse.isSameSpouseAddress", ""],
    ["spouse.birthPlace", "Tempat Lahir"],
    ["spouse.birthDate", "Tanggal Lahir"],
    ["spouse.job.jobType", "Tipe Pekerjaan"],
    ["spouse.job.jobPosition", "Tipe Pekerjaan"],
    ["spouse.job.employeeNo", "Tipe Pekerjaan"],
    ["spouse.job.companyName", "Tipe Pekerjaan"],
    ["spouse.job.companyAddress", "Tipe Pekerjaan"],
  ])

  public field_address_map = new Map<string, string>([
    ["description", "Alamat"],
    ["rt", "RT"],
    ["rw", "RW"],
    ["provinceId", "Provinsi"],
    ["cityId", "Kota/Kabupaten"],
    ["districtId", "Kecamatan"],
    ["subDistrictId", "Kelurahan"]
  ])

  public form_validation = {
    error_message: {
      required: (field = "") => `${field} harus diisi`,
      min_len: (field: string, type: 'phone' | 'nik') => `${field} minimal ${this.minLenValue[type]} digit`,
      pattern: {
        NUMBER_ONLY: 'harus berupa angka',
        ALPHABET_ONLY: 'harus berupa alphabet',
        ALPHANUMERIC_ONLY: 'harus berupa alphabet',
        ALPHABET_SPACE: 'harus berupa alphabet atau spasi',
        ALPHABET_SPACE_SPECIAL: "harus berupa alphabet atau karakter spesial (,), (.), ('), ( )",
        EMAIL: 'Format email salah',
        PHONE: 'Format No. HP harus diawali dengan 08XXX dan min 6 digit',
        PHONE2: 'Format No. Telp harus diawali dengan 02XXX dan min 6 digit',
        NO_SPACE: 'Tidak boleh ada spasi',
      },
      passwordMissmatch: (field = "") => `${field} tidak sesuai`,
    },
    rule_regex: {
      EMAIL: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      NUMBER_ONLY: /^\d+$/,
      PHONE: /^(?:08)[0-9\\s.\\/-]{6,20}$/,
      PHONE2: /^(?:02)[0-9\\s.\\/-]{6,20}$/,
      ALPHABET_ONLY: /^[a-zA-Z]+$/,
      ALPHABET_SPACE_ONLY: /^[a-zA-Z ]*$/,
      ALPHANUMERIC_ONLY: /^[a-z0-9]+$/i,
      ALPHABET_SPACE_SPECIAL: /^[a-zA-Z .,']*$/,
      NO_SPACE: /^[^\s]+/
    },
    length: {
      PHONE_MIN: 9,
      PHONE_MAX: 13,
      NAME_MAX: 26,
      NIK: 16,
      PASSPORT_MIN: 8,
      PASSPORT_MAX: 16,
    }
  }


  public async urlToB64(END_POINT: string | undefined): Promise<any> {
    const response = await fetch(process.env.NEXT_PUBLIC_API_URL + '/' + END_POINT, {
      method: 'GET',
      headers: {
        Authorization: getAuthorizationHeader()
      }
    })
    const blob = await response.blob()

    return Promise.resolve(this.getBase64(blob));
  }

  private getBase64(file: any): Promise<any> {
    return new Promise((resolve, reject) => {
      let fileInfo;
      let baseURL: string | null | ArrayBuffer = "";
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onerror = reject;
      reader.onload = () => {
        baseURL = reader.result;
        resolve((baseURL as string).split(',')[1]);
      };
      console.log(fileInfo);
    });
  };

  async handleFileInputChange(e: any): Promise<IFile | null> {
    let data = null

    const file = e.target.files[0];

    if (file) {
      const result: any = await this.getBase64(file)

      data = {
        file: result,
        contentType: file.type
      }
    };

    return data
  }



  public getErrorBag(status: number) {
    switch (status) {
      case 400:
        return status + " : Data yang dikirim tidak valid!"
      case 401:
        return status + " Maaf, anda tidak memiliki terautentikasi!"
      case 403:
        return status + " Maaf, anda tidak memiliki akses!"
      case 404:
        return status + " Maaf, Data tidak ditemukan!"
      default:
        return status + " Terjadi kesalahan, mohon tunggu beberapa saat lagi!"
    }
  }

  public sumNumbers(arr: number[]) {
    return arr.reduce((partialSum, a) => partialSum + a, 0);
  }
}