
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FiEye, FiEyeOff } from "react-icons/fi";

export type InputProps = {
  id: string;
  name: string;
  rules?: Record<string, any>;
  width?: string;
  placeholder?: string;
  disabled?: boolean;
  accept?: string;
  onChange?(e: any): void;
};

export const InputPassword: React.FC<InputProps> = ({
  id,
  name,
  rules = {},
  width = "w-full",
  placeholder = "",
  disabled = false,
  accept,
  onChange
}) => {
  const { register } = useFormContext();

  const [isPassword, setType] = useState<boolean>(true)

  return (
    <div className="relative">
      <div className="flex absolute inset-y-0 right-0 items-center justify-end pr-3" onClick={() => setType(!isPassword)}>
        {isPassword ? <FiEyeOff /> : <FiEye />}
      </div>
      <input
        {...register(name, rules)}
        autoComplete="off"
        placeholder="**********"
        type={isPassword ? 'password' : 'text'}
        name={name}
        id={id}
        readOnly={disabled}
        auto-complete="off"
        className={`form-input block ${width} border-gray-300 ${disabled ? 'bg-gray-100' : 'bg-white'} dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 sm:text-sm rounded-md`}
      />
    </div>
  );
};
