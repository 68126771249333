import { useFormContext } from "react-hook-form";
import clsx from "clsx";
import { FocusEvent } from "react";
export type InputProps = {
  id: string;
  name: string;
  type:
  | "text"
  | "email"
  | "url"
  | "password"
  | "date"
  | "datetime-local"
  | "month"
  | "search"
  | "tel"
  | "time"
  | "week"
  | "number"
  | "file";
  rules?: Record<string, any>;
  width?: string;
  placeholder?: string;
  disabled?: boolean;
  accept?: string;
  onChange?(e: any): void;
  className?: string;
  onBlur?(e: FocusEvent<HTMLInputElement>): void;
};

export const Input: React.FC<InputProps> = ({
  id,
  name,
  type,
  rules = {},
  width = "w-full",
  placeholder = "",
  disabled = false,
  accept,
  onChange,
  className = '',
  onBlur
}) => {
  const { register } = useFormContext();
  return (
    <input
      {...register(name, rules)}
      autoComplete="off"
      placeholder={placeholder}
      onBlur={onBlur}
      type={type}
      name={name}
      id={id}
      readOnly={disabled}
      auto-complete="off"
      className={clsx(`form-input block ${width} border-gray-300 ${disabled ? 'bg-gray-100' : 'bg-white'} dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 sm:text-sm rounded-md`, className)}
    />
  );
};
