import { useForm, FormProvider } from "react-hook-form";
import { InputWrapper } from "components/react-hook-form/input-wrapper";
import { Label } from "components/react-hook-form/label";
import { ErrorMessage } from "components/react-hook-form/error-message";
import { Input } from "components/react-hook-form/input";
import { useLogin } from "functions/useLogin";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import message from "json/warnings.json";
import { InputPassword } from "components/react-hook-form/input-password";
import { formUtil, helper } from "utils/helper";
import ModalRes from "components/modals/modal-response";
import { BtnLoader } from "components/loader/button-loader";

export type FormProps = {
  username: string;
  password: string;
};

const Index: React.FC = () => {
  const [showError, setErrorModal] = useState<boolean>(false)
  const [processing, setProcessing] = useState<boolean>(false)
  const [errorsServer, setErrorsServer] = useState<string>('')
  const [typeModal, setTypeModal] = useState<"FAILED" | "SUCCESS">("SUCCESS")

  const methods = useForm<FormProps>({
    defaultValues: {
      username: "",
      password: "",
    },
  });
  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const { login } = useLogin();
  const router = useRouter();

  const [loginError, setLoginError] = useState<boolean>(false)

  const [errorServerMsg, setErrorServerMsg] = useState<string>('')

  const error = "Username atau Password salah!"


  const onSubmit = async (request: FormProps) => {
    //eslint-disable-next-line
    setProcessing(true)
    const { status, data }: any = await login(request.username, request.password)
    console.log('login res : ', status);


    if (status != 200) {
      setProcessing(false)
      let errMsg = !data ? formUtil.getErrorBag(500) : error
      if (data) {
        if (data.status === 503) {
          errMsg = "Tidak bisa akses di luar jam kerja."
        }
      }
      setErrorServerMsg(errMsg)

      setTypeModal("FAILED")
      setErrorsServer(errMsg)
      setErrorModal(true)
      // setErrorServerMsg(res.title || errorMsg)
      setLoginError(true)
      return
    }

    router.push("/")
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <div className="space-y-6">
            <div className="grid grid-cols-1 gap-y-1 gap-x-2 sm:grid-cols-12">
              {errorServerMsg !== '' && (
                <InputWrapper outerClassName="sm:col-span-12">
                  <ErrorMessage>{errorServerMsg}</ErrorMessage>
                </InputWrapper>
              )}
              <InputWrapper outerClassName="sm:col-span-12">
                <Label id="username">Username</Label>
                <Input
                  id="username"
                  name="username"
                  type="text"
                  placeholder="Username"
                  rules={{ required: formUtil.form_validation.error_message.required('Username') }}
                />
                {errors?.username?.message && (
                  <ErrorMessage>{errors.username.message}</ErrorMessage>
                )}
              </InputWrapper>

              <InputWrapper outerClassName="sm:col-span-12">
                <Label id="password">Password</Label>
                <InputPassword
                  id="password"
                  name="password"
                  rules={{
                    required: formUtil.form_validation.error_message.required('Password')
                  }}
                />
                {errors?.password?.message && (
                  <ErrorMessage>{errors.password.message}</ErrorMessage>
                )}
              </InputWrapper>
            </div>
          </div>

          <div className="flex justify-start space-x-2">
            <BtnLoader
              isLoading={processing}
              text="Masuk"
              color="blue"
            />

          </div>
        </form>
      </FormProvider>
      <ModalRes
        show={showError}
        type={typeModal}
        message={errorsServer}
        onClose={() => setErrorModal(false)} />
    </>
  );
};
export default Index;
