import moment from "moment"
import 'moment/locale/id'
import { IContentType, IMomentFormat } from "types/global"
import { IAddressDetail, IMemberSalaryRange } from "types/member"

export const SHORT_DATE_FORMAT = "DD/MM/YYYY"

export class Helper {
  constructor() {
    moment.locale('id')
  }

  nullish(value: any) {
    return value || "-"
  }

  toLocalDatetime(date: any, format: IMomentFormat) {
    if (!date) return ''

    return moment(date).format(format)
  }

  formatRp(value: number | string | undefined) {
    if (!value) return 'Rp0';

    let newValue = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      // maximumFractionDigits: 0,
    }).format(Number(value)).replaceAll(/\s/g, '');

    if(value.toString().includes('.')  || newValue.includes(',00')) {
      return newValue;
    }
    return newValue + ',00';

  }

  formatMoneyStr(value: string) {
    if (!value) return 0;

    return value.replace(/[^0-9]+/g, "")
  }

  formatB64Str(str: any, type: any | IContentType) {
    if (!str) return ''

    return `data:${type};base64,${str}`
  }

  formatLoanStatusStr(str: string) {
    if (!str) return ''

    if (str.split(" ").length > 1) {
      const arrStr = str.split(' ')

      return arrStr[1];
    }

    return str
  }

  formatPhoneNumberForRequest(value: string) {
    if (!value) return "";

    let newVal = value

    if (newVal.includes("+")) {
      newVal = newVal.replace(/[^0-9 ]/g, '')
    }

    if (newVal.includes("62")) {
      newVal = newVal.replace(/62/g, '0')
    }

    return newVal
  }

  formatPhoneNumberForMask(value: string) {
    if (!value) return "";

    let newVal = value

    if (newVal.includes("+")) {
      newVal = newVal.replace(/[^0-9 ]/g, '')
    }

    if (newVal.substring(0, 2) !== "62") {
      newVal = '62' + newVal
    }

    return newVal
  }

  getSalaryTextValue(data: IMemberSalaryRange | undefined, lastIdx: boolean) {
    if (!data) return ''

    if (!data.from) {
      return `< ${this.formatRp(data.to)}`
    }
    if (lastIdx) {
      return `> ${this.formatRp(data.from)}`
    }

    return `${this.formatRp(data.from)}-${this.formatRp(data.to + 1)}`
  }

  getFullAddress(data: IAddressDetail) {
    if (!data) return ''
    if (!data.description) return ''

    let address = data.description

    if (data.rt) {
      address = address + ` RT ${data.rt}`
    }
    if (data.rw) {
      address = address + ` RW ${data.rw}`
    }
    if (data.subDistrict.name) {
      address = address + ` , ${data.subDistrict.name}`
    }
    if (data.district.name) {
      address = address + ` , ${data.district.name}`
    }
    if (data.city.name) {
      address = address + ` , ${data.city.name}`
    }
    if (data.province.name) {
      address = address + ` , ${data.province.name}`
    }

    return address
  }

  numToMonthYear(mon: number, year: number) {
    if (typeof mon !== 'number' || typeof year !== 'number') return ''
    let monIncr = mon - 1
    return moment().month(monIncr).format("MMMM") + ' - ' + moment().year(year).format("YYYY")
  }

  schemeName(name: string) {
    if (!name) return '-'

    let schemeName = name.replace(/[^a-zA-Z ]+/g, '').trim()

    if (schemeName.split(" ").length == 1) return schemeName

    return this.formatLoanStatusStr(name)
  }

  getCurrentYear() {
    return new Date().getFullYear();
  }
  
  getCurrentMonth() {
    return moment().format('M');
  }

  getCurrentDate(format: IMomentFormat) {
    return moment().format(format)
  }

  formatDateFancy(dateString: string) {
    return moment(dateString).format('dddd, DD MMM YYYY')
  }

  formatShortDate(dateString: string) {
    return moment(dateString).format(SHORT_DATE_FORMAT)
  }

}