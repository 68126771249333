import { useAppDispatch } from "store";
import { authService } from "../services/auth/index";
import Cookies from "js-cookie";
import { setUser } from "slices/user-logged-in";

export const useLogin = () => {
  const dispatch = useAppDispatch();
  const login = async (username: string, password: string) => {
    try {
      const res = await authService.login(username, password)

      if (res.status == 200) {
        const user = res.data
        const userData = {
          username: user?.username,
          position: user?.employee?.orgStructPosition?.name,
          token: user?.tokenInfo?.token
        }
        
        Cookies.set("currentUser", JSON.stringify(userData));
        // Cookies.set("currentUser", JSON.stringify(userData), {
        //   secure: process.env.NODE_ENV != 'development'
        // });
        localStorage.setItem('roles',JSON.stringify(user.roles))
        let permissions: string[] = []
        if (user.roles) {
          for (const r of user.roles) {
            for (const p of r.permissions) {
              if (!permissions.includes(p.code)) {
                permissions.push(p.code)
              }
            }
          }
        }
        localStorage.setItem('permissions',JSON.stringify(permissions))
        localStorage.setItem('officeBranch',JSON.stringify(user.officeBranch))
        localStorage.setItem('bizUnit',JSON.stringify(user.bizUnit))
      }
      return res
    } catch (error: any) {
      const newError = {
        ...error.response,
        message: error.message,
        status: 500
      }
      return newError
    }
  };

  return { login };
};